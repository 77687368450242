<template>
  <MobileHeader
    :mobileHeaderTitle="mobileHeaderTitle"
    :isShowMatchDetailButton="true"
    :isShowSocialMediaShareButton="true"
    @moreEvent="handleMore()"
    @toMatchDetailPage="openMatchDetailNewTab"
  ></MobileHeader>
  <div class="home has-header">
    <div class="container">
      <ScoreInfo
        :data="matchDetailScore"
        v-if="!isMobile"
        class="highlights-score-info"
      >
      {{ matchDetailScore }}
      </ScoreInfo>
      <!-- mt-m -->
      <div class="mb-l display-flex-center" v-if="!isMobile">
        <router-link :to="{ path: matchDetailLink }" target="_blank">
          <div class="nav-pill last-nav-pill display-flex-center">
            <MatchDetailIcon></MatchDetailIcon>
            <!-- <img class="mr-s" src="../../../../static/images/icons/icon_footer_home_selected.png"> -->
            <!-- <span> {{ $t("MATCH_DETAILS") }}</span> -->
          </div>
        </router-link>
      </div>
      <div class="match-filter" v-show="!isMobile">
        <div class="match-filter__date">
          <DatePicker
            v-model="montlyCalenderModel"
            color="#6F8C9B"
            is-dark
            firstDayOfWeek = "1"
            :locale="
              currentLocale === 'vn'
                ? 'vi'
                : currentLocale && currentLocale === 'cn'
                ? 'zh'
                : currentLocale
            "
          >
            <template #default="{ inputValue, togglePopover, hidePopover }">
              <span
                class="nav-pill selected"
                @click.stop="
                  monthlyCalendarHandle(
                    $event,
                    date,
                    togglePopover,
                    hidePopover
                  )
                "
              >
                {{ montlyCalender.date }}
              </span>
            </template>
          </DatePicker>
        </div>

        <weekly-calendar
          v-show="!isMobile"
          :params="weeklyCalendarDate"
          :show="3"
          :isShowPassDayOnly="true"
          @weeklyDateChange="selectByDateWeekly($event)"
        ></weekly-calendar>
        <div class="match-filter__social-sharing">
          <SocialMediaSharing></SocialMediaSharing>
        </div>
      </div>
      <div class="highlights-leaugelist__wrapper" v-if="!isMobile">
        <div
          class="highlights-leaguelist__side-wrapper left"
          :class="{ blur: !highlightLeague.isShowArrow }"
          @click="handleHighlightLeagueControl('prev')"
        >
          <img src="../../../../static/images/icons/icon_arrow_left_white.png" />
        </div>
        <div class="highlights-leaguelist__middle-wrapper">
          <div class="highlights-leaguelist__middle-container">
            <div v-show="!highlightLeague.isLoading && highlightLeague.isShow">
              <router-link
                :to="{
                  path: `/${currentLocale}/${currentSportType}/${highlightLeague.currentSelectedLeague.country}/${highlightLeague.currentSelectedLeague.leagueKey}`,
                  query: {
                    leagueId: highlightLeague.currentSelectedLeague.leagueId,
                    subLeagueId:
                      highlightLeague.currentSelectedLeague.subLeagueId,
                  },
                }"
                class="display-flex-align-center home-match__title-wrapper"
              >
                <div class="home-match__title">
                  {{ highlightLeague.currentSelectedLeague.country }}
                </div>
                <span
                  class="home-match__title"
                  v-if="
                    highlightLeague.currentSelectedLeague.leagueName !== null
                  "
                  >|</span
                >
                <div
                  v-if="
                    highlightLeague.currentSelectedLeague.leagueName !== null
                  "
                  class="home-match__title"
                >
                  {{ highlightLeague.currentSelectedLeague.leagueName }}
                </div>
                <div
                  class="home-match__title"
                  v-if="!!highlightLeague.currentSelectedLeague.subLeagueName"
                >
                  : {{ highlightLeague.currentSelectedLeague.subLeagueName }}
                </div>
                <div class="icon-fav small" v-if="!!highlightLeague.currentSelectedLeague.isFavLeague"></div>
              </router-link>
              <swiper
                class=""
                @swiper="setControlledSwiper"
                :navigation="{
                  nextEl: '#btnSwiperNextRounded',
                  prevEl: '#btnSwiperPrevRounded',
                }"
                :slidesPerView="3.5"
                :freeMode="true"
              >
                <swiper-slide
                  class="display-flex"
                  v-for="(o, index) in highlightLeague.currentSelectedLeague
                    .matchList"
                  :key="index"
                >
                  <MatchCard
                    :data="o"
                    :parentData="highlightLeague.currentSelectedLeague"
                    :date="currentDate"
                    :target="'_self'"
                    :classes="'mr-1rem'"
                    class="highlight-leaguelist__middle_match-card"
                    :class="{ selected: o.selected }"
                    @click="
                      handleSelectMatch(
                        o,
                        highlightLeague.currentSelectedLeague.matchList
                      )
                    "
                  ></MatchCard>
                </swiper-slide>
              </swiper>
            </div>
            <div v-show="highlightLeague.isLoading" class="display-flex mt-m">
              <div class="match-card__skeleton-wrapper">
                <div class="match-card__skeleton status"></div>
                <div class="match-card__skeleton"></div>
                <div class="match-card__skeleton"></div>
              </div>
              <div class="match-card__skeleton-wrapper">
                <div class="match-card__skeleton status"></div>
                <div class="match-card__skeleton"></div>
                <div class="match-card__skeleton"></div>
              </div>
              <div class="match-card__skeleton-wrapper">
                <div class="match-card__skeleton status"></div>
                <div class="match-card__skeleton"></div>
                <div class="match-card__skeleton"></div>
              </div>
              <div class="match-card__skeleton-wrapper">
                <div class="match-card__skeleton status"></div>
                <div class="match-card__skeleton"></div>
                <div class="match-card__skeleton"></div>
              </div>
            </div>
          </div>

          <div
            class="no-data"
            v-show="!highlightLeague.isLoading && !highlightLeague.isShow"
          >
            {{ $t("NO_MATCHES") }}
          </div>

          <div
            id="btnSwiperNextRounded"
            v-show="!highlightLeague.isLoading"
            class="highlights-leaguelist__middle-control next"
          >
            <img
              class="swiper-button__img"
              src="../../../../static/images/icons/icon_arrow_right_white.png"
            />
          </div>
          <div
            id="btnSwiperPrevRounded"
            v-show="!highlightLeague.isLoading"
            class="highlights-leaguelist__middle-control prev"
          >
            <img
              class="swiper-button__img"
              src="../../../../static/images/icons/icon_arrow_left_white.png"
            />
          </div>
        </div>
        <div
          class="highlights-leaguelist__side-wrapper right"
          :class="{ blur: !highlightLeague.isShowArrow }"
          @click="handleHighlightLeagueControl('next')"
        >
          <img src="../../../../static/images/icons/icon_arrow_right_white.png" />
        </div>
      </div>
      <div v-if="!isMobile">
        <div
          class="desktop-match-detail__thumbnail-wrapper"
          v-show="video.isShowVideoThunmnail && !video.isLoading"
        >
          <div class="desktop-match-detail__thumbnail-overview">
            <video
              class="desktop-match-detail__thumbnail-overview__video"
              controls
              autoplay
              ref="videoRef"
              v-if="isAudioType"
              :src="currentVideoUrl"
            ></video>
            <iframe
              class="desktop-match-detail__thumbnail-overview__video"
              allow="autoplay"
              v-else
              :src="currentVideoUrl"
            ></iframe>
            <!-- <div>
              <p class="desktop-match-detail__thumbnail-overview__title">
                {{ currentVideoLabel }}
              </p>
            </div> -->
          </div>
          <perfect-scrollbar class="flex-1">
            <div class="desktop-match-detail__thumbnail-section__wrapper">
              <div
                class="desktop-match-detail__thumbnail-section__container"
                v-for="(o, index) in thumbnailVideoList"
                :key="index"
                @click="showCurrentVideo(o)"
              >
                <div class="desktop-match-detail__thumbnail-section">
                  <div class="match-detail__thumbnail-section__overlay" :class="{ selected: o.selected }">
                      <img src="../../../../static/images/thumbnail_basketball_video_preview.png">
                    
                  
                    <!-- <video
                      class="desktop-match-detail__thumbnail-iframe"
                      controls
                      v-if="o.isAudioType"
                      :src="`${o.videoUrl}#t=0.1`"
                    ></video>
                    <iframe
                      class="desktop-match-detail__thumbnail-iframe"
                      v-else
                      :src="o.videoUrl"
                    ></iframe> -->
                  </div>
                </div>
                <div
                  class="desktop-match-detail__thumbnail-section__desc-wrapper"
                >
                  <!-- <div class="desktop-match-detail__thumbnail-section__desc-duration">11'minute</div> -->
                  <div
                    class="desktop-match-detail__thumbnail-section__desc-title"
                    :class="{ selected: o.selected, watched: o.watched }"
                  >
                    {{ o.label }}
                  </div>

                  <!-- <div class="desktop-match-detail__thumbnail-section__desc-tool">Video highling</div> -->
                </div>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
        <Loading v-show="video.isLoading"></Loading>
      </div>

      <div v-if="isMobile" class="match-detail__mobile-wrapper">
        <div class="match-detail__thumbnail-wrapper" v-if="video.isShowVideoThunmnail && !video.isLoading">
          <div class="match-detail__thumbnail-overview">
            <video
              class="desktop-match-detail__thumbnail-iframe"
              controls
              autoplay
              ref="videoRef"
              playsinline
              v-if="isAudioType"
              :src="currentVideoUrl"
            ></video>
            <iframe
              class="desktop-match-detail__thumbnail-iframe"
              allow="autoplay"
              v-else
              :src="currentVideoUrl"
            ></iframe>
          </div>
        </div>
      </div>

      <!-- <router-link v-if="isMobile" :to="{path:`/${currentLocale}/${currentSportType}/${highlightLeague.currentSelectedLeague.countryKey}/${highlightLeague.currentSelectedLeague.leagueKey}`,query: { leagueId: highlightLeague.currentSelectedLeague.leagueId, subLeagueId: highlightLeague.currentSelectedLeague.subLeagueId }}"  class="display-flex-align-center highlight-match__title" >   
                <div class="home-match__title">{{matchDetailScore.country}}</div>
                <span class="home-match__title" v-if="matchDetailScore.leagueName !== null">|</span>
                <div class="home-match__title"  v-if="matchDetailScore.leagueName !== null"> {{matchDetailScore.leagueName}}</div>
                <div class="home-match__title" v-if="!!highlightLeague.currentSelectedLeague .subLeagueName">: {{highlightLeague.currentSelectedLeague.subLeagueName}}</div>
            </router-link> -->
      <ScoreInfoMobile :data="matchDetailScore" v-if="isMobile"></ScoreInfoMobile>
      <!-- <div class="match-detail__wrapper" v-if="isMobile">
        <p class="home-match__time">{{ matchDetailScore.matchStatus }}</p>
        <div class="display-flex-align-center">
          <div class="flex-1">
            <Navigation
              :id="matchDetailScore.homeTeamId"
              :category="'team'"
              :name="matchDetailScore.homeTeamName"
              class="match-detail__container"
            >
              <div class="match-detail__opponent">
                <Icon
                  :logoUrl="matchDetailScore.homeLogo"
                  class="match-detail__icon"
                ></Icon>
                <span>{{ matchDetailScore.homeTeamName }}</span>
                <img
                  class="ml-1rem"
                  src="../../../../static/images/label/label_pen_win.png"
                  v-if="
                    matchDetailScore.homeTeamPenaltyScore >
                    matchDetailScore.awayTeamPenaltyScore
                  "
                />
              </div>
              <div class="display-flex relative">
                <div
                  class="match-detail__score"
                  :class="{
                    'match-detail__penatly-score':
                      matchDetailScore.homeTeamPenaltyScore,
                  }"
                >
                  {{ matchDetailScore.homeTeamScore }}
                </div>
                <div
                  class="match-detail__divider"
                  v-if="matchDetailScore.homeTeamPenaltyScore"
                ></div>
                <div
                  class="match-detail__score"
                  :class="{
                    'match-detail__penatly-score':
                      matchDetailScore.homeTeamPenaltyScore,
                  }"
                  v-if="matchDetailScore.homeTeamPenaltyScore"
                >
                  {{ matchDetailScore.homeTeamPenaltyScore }}
                </div>
              </div>
            </Navigation>
            <Navigation
              :id="matchDetailScore.awayTeamId"
              :category="'team'"
              :name="matchDetailScore.awayTeamName"
              class="match-detail__container"
            >
              <div class="match-detail__opponent">
                <Icon
                  :logoUrl="matchDetailScore.awayLogo"
                  class="match-detail__icon"
                ></Icon>
                <span>{{ matchDetailScore.awayTeamName }} </span>
                <img
                  class="ml-1rem"
                  src="../../../../static/images/label/label_pen_win.png"
                  v-if="
                    matchDetailScore.awayTeamPenaltyScore >
                    matchDetailScore.homeTeamPenaltyScore
                  "
                />
              </div>
              <div class="display-flex">
                <div
                  class="match-detail__score"
                  :class="{
                    'match-detail__penatly-score':
                      matchDetailScore.homeTeamPenaltyScore,
                  }"
                >
                  {{ matchDetailScore.awayTeamScore }}
                </div>
                <div
                  class="match-detail__score"
                  :class="{
                    'match-detail__penatly-score':
                      matchDetailScore.homeTeamPenaltyScore,
                  }"
                  v-if="matchDetailScore.awayTeamPenaltyScore"
                >
                  {{ matchDetailScore.awayTeamPenaltyScore }}
                </div>
              </div>
            </Navigation>
          </div>
          <div class="highlight-detail__info-icon__wrapper">
            <router-link :to="{ path: matchDetailLink }" target="_blank">
              <img
                src="../../../../static/images/icons/icon_highlight_detail.png"
              />
            </router-link>
          </div>
        </div>
      </div> -->

      <div
        class="match-detail__thumbnail-wrapper"
        style="margin-top: 1rem"
        v-if="isMobile"
      >
        <div
          class="highlight-match__list-title"
          v-if="thumbnailVideoList.length > 1"
        >
          {{ $t("HIGHLIGHTS") }}
        </div>
        <div class="match-detail__thumbnail-section__wrapper">
          <!-- v-show="index != 0" -->
          <div
            class="match-detail__thumbnail-section__container"
            v-for="(o, index) in thumbnailVideoList"
            :key="index"
            @click="showCurrentVideo(o)"
          >
            <div class="match-detail__thumbnail-section">
              <div class="match-detail__thumbnail-section__overlay" :class="{ selected: o.selected }">
                 <img src="../../../../static/images/thumbnail_basketball_video_preview.png">
                <!-- <video
                  class="desktop-match-detail__thumbnail-iframe"
                  controls
                  v-if="o.isAudioType"
                  :src="`${o.videoUrl}#t=0.1`"
                  :class="{ selected: o.selected }"
                ></video>
                <iframe
                  class="desktop-match-detail__thumbnail-iframe"
                  v-else
                  :src="o.videoUrl"
                  :class="{ selected: o.selected }"
                ></iframe> -->
                <!-- <div class="match-detail__thumbnail-section__play-icon"></div> -->
              </div>
            </div>
            <div class="desktop-match-detail__thumbnail-section__desc-wrapper">
              <div
                class="desktop-match-detail__thumbnail-section__desc-title"
                :class="{ selected: o.selected, watched: o.watched }"
              >
                {{ o.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Drawer ref="drawerComponent" :isShowMediaSharing="true"></Drawer>
  <PopUpVideo
    ref="popUpVideoId"
    :currentVideoUrl="currentVideoUrl"
  ></PopUpVideo>
  <div v-if="isShowCurrentVideo" class="display-flex-center video-wrapper">
    <div class="video-container">
      <div class="display-flex-fe">
        <div class="video-close" @click="closeCurrentVideo()"></div>
      </div>
      <video
        style="height: 17rem"
        ref="currentVideoId"
        v-if="isAudioType"
        controls
        :src="currentVideoUrl"
      ></video>
      <iframe :src="currentVideoUrl" style="height: 17rem" v-else></iframe>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import WeeklyCalendar from "@/components/WeeklyCalendar.vue";
import MatchCard from "@/components/share/MatchCard.vue";
import ScoreInfo from "@/components/matchDetail/ScoreInfo.vue";
import ScoreInfoMobile from "@/components/matchDetail/ScoreInfoMobile.vue";
import MobileHeader from "@/components/mobile/Header.vue";
import Drawer from "@/components/modal/Drawer.vue";
import PopUpVideo from "@/components/matchDetail/PopUpVideo.vue";
import SocialMediaSharing from "@/components/socialMediaSharing/SocialMediaSharing.vue";
import { DatePicker } from "@/lib/v-calendar";
import MatchDetailIcon from "@/components/ui/MatchDetailIcon.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";
import config from "@/js/config.js";
import moment from "moment";

import { Swiper, SwiperSlide } from "swiper/vue";
// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);
export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    MobileHeader,
    WeeklyCalendar,
    MatchCard,
    Drawer,
    ScoreInfo,
    ScoreInfoMobile,
    PopUpVideo,
    SocialMediaSharing,
    DatePicker,
    MatchDetailIcon,
  },
  data() {
    return {
      matchDetailLink: "",

      mobileHeaderTitle: "HIGHLIGHTS",
      displayDateFormat: config.displayDateFormat,
      displayCNDateFormat: config.displayCNDateFormat,
      apiParamDateFormat: config.apiParamDateFormat,
      cnLocaleList: config.cnLocaleList,
      montlyCalender: {
        date: "",
        selected: false,
        isFirstime: false,
      },
      montlyCalenderModel: "",
      weeklyCalendarDate: "",
      currentDate: "",

      isShowCurrentVideo: false,
      currentVideoUrl: "",
      currentVideoLabel: "",
      isAudioType: false,

      video: {
        isShowVideoThunmnail: false,
        isLoading: false,
      },
      thumbnailVideoList: [],
      interval: null,

      swiper: null,
      isLoading: false,
      highlightLeague: {
        isShow: false,
        isShowArrow: true,
        isLoading: false,
        index: 0,
        list: {},
        matchInfo: {
          matchName: "",
          matchID: "",
        },
        currentSelectedLeague: {
          leagueId: null,
          country: "",
          leagueName: "",
        },
      },
    };
  },

  beforeUnmount() {
    clearInterval(this.interval);
    this.removeVisibilityChangeListener();
  },

  created() {
    //this.matchId = this.$route.params.matchId;
  },

  computed: {
    ...mapGetters([
      "matchDetailScore",
      "isMobile",
      "currentLocale",
      "currentSportType",
      "isStopTimmer",
    ]),
  },
  watch: {
    $route() {
      // this.matchId = this.$route.params.matchId;
      // this.getMatchDetailScoreData();
      // this.getMatchDetailHighLightListData();
      // this.intMatchDetailLink();

      // // when change multilanguage options translate the display date also
      // let date;
      // if (this.currentDate === '') {
      //     date = this.$route.query.date
      // } else {
      //     date = this.currentDate
      // }
      // this.montlyCalender.date = moment(date).format(this.displayDateFormat);
      this.init();
    },
    montlyCalenderModel: {
      deep: true,
      handler(newVal, oldVal) {
        if (typeof hideToggleElement !== "undefined") {
          setTimeout(() => hideToggleElement({ ref: hideToggleTarget }), 500); // noob fix vcalendar bug (somethimes dont close the calendar automatic);
        }

        this.syncMonthlyWeeklyCalendar(newVal === null ? oldVal : newVal);

        this.montlyCalender.selected = true;
        this.getMatchListData();
      },
    },
    matchDetailScore: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          let routeParams = this.$route.params;
          let patt = / /g;
          let newRouteParamsOpponent = newVal.homeTeamName.replace(patt, "-") + "-vs-" + newVal.awayTeamName.replace(patt, "-");
          if (routeParams.opponent !== newRouteParamsOpponent) {
            this.$router.replace(decodeURI(this.$route.fullPath).replace(routeParams.opponent, newRouteParamsOpponent));
          }
        }
      },
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    ...mapActions([
      "getBasketballMatchDetailScore",
      "getBasketballMatchDetaiHighlightList",
      "getBasketballHighlightList",
      "commonVerifyLogin",
    ]),
    ...mapMutations(["SUCUESS_GET_MATCH_DETAIL_SCORE"]),

    init() {
      this.matchId = this.$route.params.matchId;
      this.commonVerifyLogin();
      this.intMatchDetailLink(); //match detail link
      this.getMatchDetailScoreData(); //score
      this.getMatchDetailHighLightListData(); //video
      this.initHighlightLeague(); //match list
      this.addVisibilityChangeListener();
    },

    // react to pause highlight video if user switched away from the page / tab
    toggleTabHandler() {
      if (document.visibilityState === "hidden") {
        this.$refs.videoRef.pause();
      }
    },

    addVisibilityChangeListener() {
      document.addEventListener("visibilitychange", this.toggleTabHandler);
    },

    removeVisibilityChangeListener() {
      document.removeEventListener("visibilitychange", this.toggleTabHandler);
    },

    selectByDateWeekly(e) {
      this.syncMonthlyWeeklyCalendar(e.fullDate);
      this.getMatchListData();
    },

    intMatchDetailLink() {
      this.$nextTick(() => {
        let routeParams = this.$route.params;
        this.matchDetailLink = `/${this.currentLocale}/${this.currentSportType}/${routeParams.country}/${routeParams.club}/${routeParams.opponent}/${routeParams.matchId}`;
      });
    },

    initHighlightLeague() {
      let date = new Date();

      // let sessionStorageHighlightDate = localStorage.getItem("highlightsDate") === null ? null: localStorage.getItem("highlightsDate");
      // if (sessionStorageHighlightDate !== null) {
      //     date = sessionStorageHighlightDate
      // }
      date = this.$route.query.date;

      this.syncMonthlyWeeklyCalendar(date);
      this.getMatchListData();
    },

    openNewWindow(url) {
      window.open(
        url,
        "_blank",
        "location=yes,height=570,width=420,scrollbars=yes,status=yes"
      );
    },

    async getMatchListData() {
      let params = {
        leagueIdList: "",
        matchDate: this.currentDate,
        timeZone: this.$tools.getCurrentTimeZone(),
      };

      this.highlightLeague.isLoading = true;
      const result = await this.getBasketballHighlightList(params);
      this.highlightLeague.isLoading = false;

      if (result.result.leagueMatchList.length === 0) {
        this.highlightLeague.isShow = false;
      } else {
        this.highlightLeague.isShow = true;
        this.matchList = result.result.leagueMatchList;
        this.highlightLeague.list = this.matchList;
        this.handleHighlightLeagueControl();
      }
    },
    setControlledSwiper(swiper) {
      this.swiper = swiper;
    },
    handleHighlightLeagueControl(action) {
      let limit = this.matchList.length - 1;
      let index = 0;
      if (limit === 0) {
        this.highlightLeague.isShowArrow = false;
      } else {
        this.highlightLeague.isShowArrow = true;
      }
      if (action === "next") {
        if (this.highlightLeague.index < limit) {
          this.highlightLeague.index++;
        } else {
          this.highlightLeague.index = 0;
        }
        index = this.highlightLeague.index;
      } else if (action === "prev") {
        if (this.highlightLeague.index > 0) {
          this.highlightLeague.index--;
        } else {
          this.highlightLeague.index = limit;
        }

        index = this.highlightLeague.index;
      } else {
        for (let i = 0; i < this.highlightLeague.list.length; i++) {
          for (
            let level2 = 0;
            level2 < this.highlightLeague.list[i].matchList.length;
            level2++
          ) {
            if (
              this.highlightLeague.list[i].matchList[
                level2
              ].matchId.toString() === this.matchId
            ) {
              this.highlightLeague.list[i].matchList[level2].selected = true;
              index = i;

              this.highlightLeague.matchInfo.matchId =
                this.highlightLeague.list[i].matchList[level2].matchId;

              this.highlightLeague.matchInfo.matchName =
                this.highlightLeague.list[i].matchList[level2].matchName;

              setTimeout(() => {
                this.swiper.slideTo(level2, 300);
              }, 100);
            }
          }
        }
      }

      this.highlightLeague.currentSelectedLeague =
        this.highlightLeague.list[index];
    },
    handleSelectMatch(o, parent) {
      this.highlightLeague.currentSelectedLeague.matchList;

      for (
        let level1 = 0;
        level1 < this.highlightLeague.list.length;
        level1++
      ) {
        for (
          let level2 = 0;
          level2 < this.highlightLeague.list[level1].matchList.length;
          level2++
        ) {
          this.highlightLeague.list[level1].matchList[level2].selected = false;
        }
      }
      // for(let i=0; i<parent.length; i++) {
      //     parent[i].selected =false;
      // }
      o.selected = true;
      this.highlightLeague.matchInfo.matchId = o.matchId;
      this.highlightLeague.matchInfo.matchName = o.matchName;
    },

    async getMatchDetailScoreData() {
      let result = await this.getBasketballMatchDetailScore({
        matchId: this.matchId,
        timeZone: this.$tools.getCurrentTimeZone(),
      });
      this.interval = setInterval(async () => {
        if (this.isStopTimmer) {
          clearInterval(this.interval);
        } else {
          result = await this.getBasketballMatchDetailScore({
            matchId: this.matchId,
            timeZone: this.$tools.getCurrentTimeZone(),
          });
        }
      }, 3000);
      this.SUCUESS_GET_MATCH_DETAIL_SCORE(result.result);
    },
    async getMatchDetailHighLightListData() {
      this.video.isLoading = true;
      this.thumbnailVideoList = []; //reset video
      this.currentVideoUrl = ""; //reset video

      let params = {
        matchId: this.matchId,
      };
      const result = await this.getBasketballMatchDetaiHighlightList(params);

      setTimeout(() => {
        this.video.isLoading = false;
      }, 300);

      if (result.result === null) {
        this.video.isShowVideoThunmnail = false;
      } else {
        this.video.isShowVideoThunmnail = true;

        for (let i = 0; i < result.result.length; i++) {
          result.result[i].selected = false;
        }

        this.thumbnailVideoList = result.result;
        for (let i = 0; i < this.thumbnailVideoList.length; i++) {
          //  if (this.$tools.isAudioType(this.thumbnailVideoList[i].videoUrl)) {
          if (/\.(mp3|mp4)$/i.test(this.thumbnailVideoList[i].videoUrl)) {
            this.thumbnailVideoList[i].isAudioType = true;
          } else {
            this.thumbnailVideoList[i].isAudioType = false;
          }

          if (
            this.thumbnailVideoList[i].label === "" ||
            this.thumbnailVideoList[i].label === null
          ) {
            //if label === '' or null then display '-'
            this.thumbnailVideoList[i].label = "-";
          }
        }

        // if (!this.isMobile) {
          this.showCurrentVideo(this.thumbnailVideoList[0]); //in desktop view, default click the first thumnail video list video;
        // } else {
          // this.currentVideoUrl = this.thumbnailVideoList[0].videoUrl; //in mobile view default click the first thumnail video list video; for responsive purpose
        // }

        this.currentVideoLabel = this.thumbnailVideoList[0].label;

        this.$nextTick(() => {
          this.$refs.videoRef.play();
        });
      }
    },
    showCurrentVideo(o) {
      // if (this.isMobile) {
      //   this.currentVideoUrl = o.videoUrl;
      //   this.$refs.popUpVideoId.openCurrentVideo();
      //   //this.isShowCurrentVideo = true;
      // } else {
      //   this.$refs.popUpVideoId.closeCurrentVideo();
      // }

      if (o.isAudioType) {
        // check whether use iframe or video tag to implement incoming video url
        this.isAudioType = true;
      } else {
        this.isAudioType = false;
      }

      this.currentVideoUrl = o.videoUrl;
      this.currentVideoLabel = o.label;

      o.watched = true;

      this.thumbnailVideoList.forEach((x) => {
        x.selected = false;
      });
      o.selected = true;
    },
    closeCurrentVideo() {
      // this.$refs.currentVideoId.pause()
      this.isShowCurrentVideo = false;
    },
    formatMontlyCalender(date) {
      if (this.cnLocaleList.includes(this.currentLocale)) {
        this.montlyCalender.date = moment(date).format(this.displayCNDateFormat); //for montly calendar usage
      } else {
        this.montlyCalender.date = moment(date).format(this.displayDateFormat); //for montly calendar usage
      }
    },
    syncMonthlyWeeklyCalendar(date) {
      this.weeklyCalendarDate = moment(date).format(this.apiParamDateFormat);
      this.formatMontlyCalender(date);

      this.montlyCalenderModel = date;

      this.currentDate = moment(date).format(this.apiParamDateFormat);
    },
    monthlyCalendarHandle(e, date, toggle, hideToggle) {
      toggle({ ref: e.target });

      hideToggleTarget = e.target;
      hideToggleElement = hideToggle;
    },
    async handleMore() {
      if (this.$tools.mobileCheck()) {
        const shareData = {
          title: "Watch latest highlights on Asiasport.com",
          text: `Watch highlights: ${this.matchDetailScore.homeTeamName} ${this.matchDetailScore.homeTeamScore} - ${this.matchDetailScore.awayTeamScore} ${this.matchDetailScore.awayTeamName}`,
          url: window.location.href,
        };
        try {
          await navigator.share(shareData);
        } catch (err) {}
      } else {
        this.$refs.drawerComponent.showDrawer();
      }
    },
    openMatchDetailNewTab() {
      window.open(this.matchDetailLink, "_blank");
    },
  },
};
</script>
<style scoped>
/* .highlights-score-info {
  margin-bottom: 0;
} */
.match-detail__mobile-wrapper {
  margin-bottom: 0.5rem;
}
.highlights-leaugelist__wrapper {
  min-height: 10.2rem;
  display: flex;
  margin-bottom: 1.5rem;
}
.highlights-leaguelist__side-wrapper {
  width: 2.2rem;
  min-height: 100%;
  background-color: #4b616c;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.highlights-leaguelist__side-wrapper:hover {
  background-color: #7092a2;
}
.highlights-leaguelist__side-wrapper.left {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.highlights-leaguelist__side-wrapper.right {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.highlights-leaguelist__middle-wrapper {
  background-color: #35454d;
  flex: 1;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
  width: calc(100% - 6.4rem);
}
.highlights-leaguelist__middle-container {
  margin: 0 3rem;
}
.highlights-leaguelist__middle-control {
  position: absolute;
  top: 2.45rem;
  width: 2.2rem;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.highlights-leaguelist__middle-control:hover {
  background-color: #7092a2;
}
.highlights-leaguelist__middle-control.swiper-button-disabled {
  opacity: 0.35;
}
.highlights-leaguelist__middle-control.swiper-button-disabled:hover {
  background-color: transparent;
}
.highlights-leaguelist__middle-control.next {
  right: 1.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.highlights-leaguelist__middle-control.prev {
  left: 1.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.highlight-leaguelist__middle_match-card {
  min-width: 15rem;
}
.highlight-match__title {
  margin: 0.8rem 1rem 0.3rem 1rem;
  font-size: 0.9rem;
  font-weight: 700;
}
.highlight-match__list-title {
  margin: 0 1rem 0.3rem 1rem;
}
.desktop-match-detail__thumbnail-wrapper {
  display: flex;
  flex-direction: row;
  height: 28rem;
}
.desktop-match-detail__thumbnail-overview__video {
  width: 100%;
  height: calc(100% - 1.5rem);
  border: none;
  margin-bottom: 1.5rem;
}
.desktop-match-detail__thumbnail-overview {
  width: 65.5%;
  position: relative;
  margin-right: 2rem;
}
.desktop-match-detail__thumbnail-overview__title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3;
  margin-bottom: 0.2rem;
  min-height: 2.2rem;
  font-size: 1.13rem;
  font-weight: 700;
}
.desktop-match-detail__thumbnail-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.desktop-match-detail__thumbnail-overview img {
  width: 100%;
  height: 100%;
}
.desktop-match-detail__thumbnail-section__wrapper {
  /* width: 30%; */
  /* overflow-y:auto; */
  cursor: pointer;
}
.desktop-match-detail__thumbnail-section {
  position: relative;
  margin-right: 0.5rem;
  height: 5.5rem;
  width: 9.7rem;
}
.desktop-match-detail__thumbnail-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.desktop-match-detail__thumbnail-section__container {
  display: flex;
  padding: 0.5rem 0;
  border-bottom: 0.1rem solid #35454d;
  margin-right: 1.5rem;
}
.desktop-match-detail__thumbnail-section__container:first-child {
  padding-top: 0;
}
.desktop-match-detail__thumbnail-section__desc-wrapper {
  width: calc(100% - 6.7rem);
  padding-top: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.desktop-match-detail__thumbnail-section__desc-duration {
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
}
/* alawys show blue color customer request on 13/9/2021 */
.desktop-match-detail__thumbnail-section__desc-title {
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--bg-selected) !important;
  margin-bottom: 0.4rem;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  margin-bottom: 0.2rem;
  min-height: 2.2rem;
  text-align: left;
}
.desktop-match-detail__thumbnail-section__desc-title.watched {
  color: #6f8c9b;
}
.desktop-match-detail__thumbnail-section__desc-title.selected {
  color: #2e8fef;
}

.desktop-match-detail__thumbnail-section__desc-tool {
  font-size: 0.7rem;
}

.match-detail__thumbnail-wrapper {
}
.match-detail__thumbnail-overview {
  position: relative;
  height: 13rem;
  overflow: hidden;
}
.match-detail__thumbnail-overview-desc {
  font-size: 0.875rem;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  background-color: var(--color-grey-20);
  padding: 1rem;
  margin-bottom: 0.2rem;
}
.match-detail__thumbnail-overview img {
  width: 100%;
  height: 100%;
}
.match-detail__thumbnail-section__overlay {
  position: absolute;
  background-color: rgb(0 0 0 / 22%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.match-detail__thumbnail-section__overlay.selected {
  border: 2px solid #2e8fef;
}
.match-detail__thumbnail-section__play-icon {
  width: 1rem;
  height: 1rem;
  background: url("../../../../static/images/icons/icon_play.png") center/cover
    no-repeat;
}
.match-detail__thumbnail-section__play-desc {
  font-size: 0.7rem;
  margin-top: 0.2rem;
}
.match-detail__thumbnail-section__wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 0.8rem;
  margin-bottom: 1rem;
}
.match-detail__thumbnail-section__container {
  min-width: 36%;
  padding: 0.5rem 0.5rem 0.2rem 0;
  display: flex;
  border-bottom: 0.1rem solid #35454d;
}
.match-detail__thumbnail-section {
  position: relative;
  padding-top: 0;
  overflow: hidden;
  width: 9rem;
  height: 6rem;
  margin-right: 1rem;
}
.match-detail__thumbnail-section img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.match-detail__wrapper {
  margin: 0 0.2rem 0 0.2rem;
  /* background-color: #212B30; */
  color: #cfd9de;
  padding: 0.5rem 0.8rem 0;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  text-align: left;
  min-width: 12.75rem;
  display: block;
  position: relative;
}
.match-detail__divider {
  width: 0.15rem;
  background-color: var(--color-grey-40);
  height: 4.2rem;
  position: absolute;
  right: 1.7rem;
  top: -0.3rem;
}
.match-detail__penatly-score {
  width: 1.8rem !important;
  text-align: center;
}
.match-detail__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  font-size: 0.85rem;
}

.match-detail__opponent {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cfd9de;
}
.match-detail__score {
  color: white;
}
.match-filter__date {
  position: absolute;
  left: 0;
  text-transform: capitalize;
}
.match-filter__social-sharing {
  position: absolute;
  right: 0;
}
.match-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  position: relative;
}
.match-filter__child {
  color: white;
  border-radius: 1rem;
  padding: 0.4rem 1.1rem;
  margin-right: 1rem;
  cursor: pointer;
  font-size: 0.9rem;
}
.video-wrapper {
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 80%);
}
.video-close {
  width: 1rem;
  height: 1rem;
  display: flex;
  float: right;
  margin: 0 1rem 0.5rem 0;
  background: url("../../../../static/images/icons/icon_close_white.png")
    center/cover no-repeat;
}
.video-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.video-container > iframe {
  border: none;
  width: 100%;
}
.highlight-detail__info-icon__wrapper {
  margin-left: 1rem;
  padding-bottom: 1rem;
}
@media (max-width: 768px) {
  .match-detail__info-replay {
    height: 5rem;
  }
}
@media (min-width: 1000px) {
  .match-detail__info-timeline__middle {
    flex: 5.6 !important;
  }
}
</style>
